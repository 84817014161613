import Vue from "vue";

/*TITLE*/
document.title = "Edificio Once | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Edificio Once";
Vue.prototype.$subtitle = "";

/*INTRO*/
Vue.prototype.$promoter = "Edificio Once";
Vue.prototype.$introSubtitle = "";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-op03-vela-smoked-596x596--20230113080135.jpg";
Vue.prototype.$image_kitchen = "";
Vue.prototype.$image_bath1 = "banopral-op03-vela-smoked-596x596-vela-smoked-316x90-limit-vela-smoked-316x90--20230113080157.jpg";
Vue.prototype.$image_bath2 = "banosec-op03-vela-smoked-596x596-vela-smoked-316x90-limit-vela-smoked-316x90--20230113080119.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "porcelanosa_edificio-once_fachada-1-v3_hd--20230113080143.jpg";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-op03-vela-smoked-596x596--20230113080135.jpg",
  },
  {
    src: "salon-op02-tortona-piazza-120x145--20230113080142.jpg",
  },
  {
    src: "salon-op01-lutier-marron-18x220--20230113080147.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banopral-op03-vela-smoked-596x596-vela-smoked-316x90-limit-vela-smoked-316x90--20230113080157.jpg",
  },
  {
    src: "banopral-op02-vela-natural-596x596-vela-natural-316x90-limit-vela-natural-316x90--20230113080106.jpg",
  },
  {
    src: "banopral-op01-urban-natural-nature-596x596-urban-natural-nature-316x90-cubik-uran-natural-nature-316x90--20230113080112.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banosec-op03-vela-smoked-596x596-vela-smoked-316x90-limit-vela-smoked-316x90--20230113080119.jpg",
  },
  {
    src: "banosec-op02-vela-natural-596x596-vela-natural-316x90-limit-vela-natural-316x90--20230113080126.jpg",
  },
  {
    src: "banosec-op01-urban-natural-nature-596x596-urban-natural-nature-316x90-cubik-uran-natural-nature-316x90--20230113080134.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
  {
    src: "porcelanosa_edificio-once_fachada-1-v3_hd--20230113080143.jpg",
  },
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "6";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://360.porcelanosapartners.com/EDIFICIO_ONCE/";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=c%20angel%20bruna%20nº14%20cartagena%20murcia",
    text: "C/ Angel Bruna nº14, Cartagena, Murcia",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=plaza%20españa%206%20bajo%20cartagena%20murcia",
    text: "Plaza España 6 bajo, Cartagena, Murcia",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:968505566",
    text: "968505566",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@agrourbanalevante.com",
    text: "info@agrourbanalevante.com",
  },
];
